.mailContainer{
  display: flex;
  background: #E8EEEF;
   /*height: 100vh;*/
  justify-content: space-between;
}
.selectDropdown{
  width: 35%;
  border-right: 1px solid #eee;
  padding: 5% 50px;
  background: #F4F7F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inputGroup{
    display: flex;
    width: 100%;
    margin-top: 3%;
}
.selectFeedback{
  width:75%;
  margin-bottom: 10px;
}
.selectCity, .selectCategory, .selectSubscription, .selectType{
    width: 100%;
    /* margin: auto; */
    margin-bottom: 3%;
}
.selectCity:focus, .selectCategory:focus, .selectSubscription:focus, .selectType:focus, .selectFeedback:focus{
  outline: none !important;
}
.tableSection {
  padding-top: 5%;
  width: 30%;
  margin-top: auto;
  margin-bottom: auto;
}
.tox-tinymce{
  width: 100%;
}
.mainEditor {
  // padding-top:4em;
  display: flex;
  /*align-items: center;*/
  justify-content: center;
  padding-top: 5%;
  width: 80%;
  height: 800px;
  /* position: absolute;
  right: 5%; */
}
.tabsDetails {
  // margin-top:-4em;
  // width: inherit;
  // height: inherit;
  width: 45vw;
  // height: 68vh;

}
.tox .toxEditArea__iframe, .toxMenubar+.toxToolbarOverlord .toxToolbar__primary, .tox .toxMenubar{
  background-color: #E8EEEF !important;
}

.textArea{
  width: 100%;
}


#comment_text {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
  color: black;
  font-size: 16px;
  line-height: 1.4em;
  padding: 5px 8px;
  background-color: #E8EEEF;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  height: 580px;
}
#comment_text:focus {
  background-color: #E8EEEF;
    outline-width: 0;
}
.btn{
  background:white;
  display: block;
  margin: 20px auto;
    color:black;
    border:1px solid black;
    padding: 0.5em 2em;
    text-decoration:none;
  cursor:pointer;
}
.btn:disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    color: #666666;
}
* {
    box-sizing: border-box;
  }
  .row {
    width: 100%;
  }
  .textField {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-size: 16px;
    background-color: #E8EEEF;
    margin-top: 15px;
  }
 .status label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  iframe .mceContenBbody {
    padding: 0 50px !important;
  }
  #subject, #from-name, #from-email, .mceTinymce, #title, #sub_title, #author, #url {
    margin-bottom: 3% !important;
  }
  .mceStatusbar .mceContainerBody {
    display: none !important;
  }
  #my_id{
    color: gray !important;
  }
  .tab iframe {
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    height: 600px;
    width: 100%;
    margin-top: 4%;
    background-color: white;
  }
  @media screen and (max-width: 600px) {
     .col-75 {
      width: 100%;
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1270px) {
    .mailContainer {
     display: block;
   }
   .selectDropdown{
     width:100%;
   }
   .mainEditor{
    width: 95%;
   }
 }
 @media screen and (max-width: 1270px) and (min-width: 650px) {
  .selectCity, .selectCategory, .selectSubscription, .selectType, .selectFeedback{
    width: 75%;
  }
.textField{
    width: 75%;
  }
  
}

 .error {
   color: red;
   margin-bottom: 0px;
   margin-top: auto;
   text-align: left;
   font-size: 12px;
 }
 .loader {
  border: 5px solid #fff;
  border-top: 5px solid #32B265;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 1302;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.appOverlay {
  position: absolute;
  width: 100%;
  height: 106%;
  background-color: gray;
  padding: 10px;
  opacity: 0.5;
  z-index: 1301;
}
 .success, .errorMsg {
  border: 1px solid;
  margin: 10px 0px;
  padding: 15px 10px 11px 19px;
  background-repeat: no-repeat;
  background-position: 10px center;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 1302;
  height: 130px;
  width: 200px;
  text-align: center;
}
.success {
  color: #4F8A10;
  background-color: #DFF2BF;
  background-image: url('https://i.imgur.com/Q9BGTuy.png');
  background-position: center;
}
.errorMsg{
  color: #D8000C;
  background-color: #FFBABA;
  background-image: url('https://i.imgur.com/GnyDvKN.png');
  background-position: center;
}
.err {
  color: #D8000C;
  border: 1px solid #D8000C;
}
.sent {
  color: #4F8A10;
  border: 1px solid #4F8A10;
}
.confirm {
  background: transparent;
  display: block;
  /* color: black;
  border: 1px solid black; */
  padding: 0.5em 1em;
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  top: 55%;
  left: 52%;
  z-index: 1302;
  margin: 20px;
}
.tab {
  width:50vw;
  padding:25px;
  font-family:sans-serif;
  color:#444;
}
ul.inline{
  list-style:none;
  padding: 0;
  margin-bottom:0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}
// .inline li{
//   display:inline-block;
//   margin-left:0;
//   padding:10px;
//   border-bottom:2px solid #eee;
//   transition: all .5s;
//   font-family:sans-serif;
//   font-weight:300;
//   cursor:pointer;
//   color:#aaa;
// }
ul .selected {
  border-bottom:2px solid #32B265;
  color:#444;
}
.dFlex{

  display: flex;
  justify-content: flex-end;
  padding-bottom: 1%;
  align-items: center;
}
.status {
  margin: 0;
  color: gray;
}
.switch {
  position: relative;
  /* display: inline-block; */
  width: 50px;
  height: 25px;
  margin-left: 8px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.6;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.flexRadio{
  display: flex;
  height: 60px;
}
.radioOption{
  display: flex;
  margin-top: 15px;
  margin-right: 3px;
}
.textField.radio:empty {
  display: none;
}
/* style label */
.textField.radio:empty ~ label {
    text-indent: 3.25em;
    /* margin-top: 2em; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}
.textField.radio:empty ~ label:before {
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
  margin-right: 5px;
  content: '\2714';
  text-indent: .3em;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
}
.fileMsg {
  height: 45px;
  padding-top: 10px;
  color: grey;
}
/* toggle hover */
.textField.radio:hover:not(:checked) ~ label:before {
    content:'\2714';
    /* text-indent: .9em; */
    color: #C2C2C2;
}
.textField.radio:hover:not(:checked) ~ label {
    color: #888;
}
/* toggle on */
.textField.radio:checked ~ label:before {
      content: '\2714';
    text-indent: .3em;
    color: #9CE2AE;
    background-color: #4DCB6D;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textField.radio:checked ~ label {
    color: #777;
}
/* radio focus */
.textField.radio:focus ~ label:before {
    box-shadow: 0 0 0 3px #999;
}
.ReactTable .rt-td{
  word-break: break-word !important;
  white-space: unset !important;
  text-overflow: unset !important;
}
.tableContainer{
  padding-top: 6em;
  width:85%;
}
.feedbackFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.callStatusContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.faCalendarCheckO{
  cursor: pointer;
}
textarea:focus{
  outline: none;
}
.attendedBtn {
  background-color: rgb(36, 165, 87);
  border: 0px;
  padding: 8px 11px;
  color: rgb(255, 255, 255);
  margin: 10px 0px 0px;
  width: auto;
  display: block;
  border-radius: 3px;
  width: 100px;
  cursor: pointer;
}
.attendedBtn:focus{
  outline: none;
}
.not-found {
  color: gray;
}
.quoteImgsThumbs {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
  padding: 0.75rem;
}
.quoteImgsThumbs--hidden {
  display: none;
}
.imgPreviewThumb {
  background: #fff;
  border: 1px solid #777;
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.12);
  margin-right: 1rem;
  max-width: 140px;
  padding: 0.25rem;
}

.showForSr{
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
  margin-top: 30%;
}
.button.hollow {
  border: 1px solid #32B265;
  color: #32B265;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}

.mailContainer{
  display: flex;
  background: #E8EEEF;
   /*height: 100vh;*/
  justify-content: space-between;
}
.selectDropdown{
  width: 35%;
  border-right: 1px solid #eee;
  padding: 5% 50px;
  background: #F4F7F8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inputGroup{
    display: flex;
    width: 100%;
    margin-top: 3%;
}
.selectFeedback{
  width:75%;
  margin-bottom: 10px;
}
.selectCity, .selectCategory, .selectSubscription, .selectType{
    width: 100%;
    /* margin: auto; */
    margin-bottom: 3%;
}
.selectCity:focus, .selectCategory:focus, .selectSubscription:focus, .selectType:focus, .selectFeedback:focus{
  outline: none !important;
}
.tableSection {
  padding-top: 5%;
  width: 30%;
  margin-top: auto;
  margin-bottom: auto;
}
.toxTinymce{
  width: 100%;
}
.mainEditor {
  display: flex;
  // padding-top: 8em;
  /*align-items: center;*/
  justify-content: center;
  /*padding-top: 5%;*/
  width: 80%;
  height: 800px;
  /* position: absolute;
  right: 5%; */
}
// .tabsDetails {
//   // margin-top: 55px;
//   width: inherit;
//   height: inherit;
// }
.tox .toxEditArea__iframe, .toxMenubar+.toxToolbarOverlord .toxToolbar__primary, .tox .toxMenubar{
  background-color: #E8EEEF !important;
}
#comment_text {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
  color: black;
  font-size: 16px;
  line-height: 1.4em;
  padding: 5px 8px;
  background-color: #E8EEEF;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  height: 580px;
}
#comment_text:focus {
  background-color: #E8EEEF;
    outline-width: 0;
}
.btn{
  background:white;
  display: block;
  margin: 20px auto;
    color:black;
    border:1px solid black;
    padding: 0.5em 2em;
    text-decoration:none;
  cursor:pointer;
}
.btn:disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    color: #666666;
}
* {
    box-sizing: border-box;
  }
  .row {
    width: 100%;
  }
  /*input[type=text] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
    font-size: 16px;
    background-color: #E8EEEF;
  }*/
  // label {
  //   padding: 12px 12px 12px 0;
  //   display: inline-block;
  // }
  iframe .mceContentBody {
    padding: 0 50px !important;
  }
  #subject, #from-name, #from-email, .mce-tinymce, #title, #sub_title, #author, #url {
    margin-bottom: 3% !important;
  }
  .mceStatusbar .mceContainerBody {
    display: none !important;
  }
  #my_id{
    color: gray !important;
  }
  .tab iframe {
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;
    height: 600px;
    width: 100%;
    margin-top: 4%;
    background-color: white;
  }
  @media screen and (max-width: 600px) {
     .col-75 {
      width: 100%;
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1270px) {
    .mailContainer {
     display: block;
   }
   .selectDropdown{
     width:100%;
   }
   .mainEditor{
    width: 95%;
   }
 }
 @media screen and (max-width: 1270px) and (min-width: 650px) {
  .selectCity, .selectCategory, .selectSubscription, .selectType, .selectFeedback{
    width: 75%;
  }
  .textField{
    width: 75%;
  }

  .validateContainer{
    width: 60% !important;
  }
}
 /* validate */
 .validateContainer{
  width: 500px;
  margin: auto;
  padding-top: 2%;
 }
 .validateContainer .myinput{
   background-color: transparent !important;
 }
 .error {
   color: red;
   margin-bottom: 0px;
   margin-top: auto;
   text-align: left;
   font-size: 12px;
 }
 .loader {
  border: 5px solid #fff;
  border-top: 5px solid #32B265;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 1302;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.appOverlay {
  position: absolute;
  width: 100%;
  height: 106%;
  background-color: gray;
  padding: 10px;
  opacity: 0.5;
  z-index: 1301;
}
 .success, .errorMsg {
  border: 1px solid;
  margin: 10px 0px;
  padding: 15px 10px 11px 19px;
  background-repeat: no-repeat;
  background-position: 10px center;
  position: fixed;
  top: 45%;
  left: 48%;
  z-index: 1302;
  height: 130px;
  width: 200px;
  text-align: center;
}
.success {
  color: #4F8A10;
  background-color: #DFF2BF;
  background-image: url('https://i.imgur.com/Q9BGTuy.png');
  background-position: center;
}
.errorMsg{
  color: #D8000C;
  background-color: #FFBABA;
  background-image: url('https://i.imgur.com/GnyDvKN.png');
  background-position: center;
}
.err {
  color: #D8000C;
  border: 1px solid #D8000C;
}
.sent {
  color: #4F8A10;
  border: 1px solid #4F8A10;
}
.confirm {
  background: transparent;
  display: block;
  /* color: black;
  border: 1px solid black; */
  padding: 0.5em 1em;
  text-decoration: none;
  cursor: pointer;
  position: fixed;
  top: 55%;
  left: 52%;
  z-index: 1302;
  margin: 20px;
}
.tab {
  width:100%;
  /* padding:25px; */
  font-family:sans-serif;
  color:#444;
}
ul.inline{
  list-style:none;
  padding: 0;
  margin-bottom:0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}
.inline li{
  display:inline-block;
  margin-left:0;
  padding:10px;
  border-bottom:2px solid #eee;
  transition: all .5s;
  font-family:sans-serif;
  font-weight:300;
  cursor:pointer;
  color:#aaa;
}
ul .selected {
  border-bottom:2px solid #32B265;
  color:#444;
}
.dFlex{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1%;
  align-items: center;
}
.status {
  margin: 0;
  color: gray;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.6;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.toggleList:checked + .slider {
  background-color: #32B265;
}
.toggleList:focus + .slider {
  box-shadow: 0 0 1px #32B265;
}
.toggleList:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
.flexRadio{
  display: flex;
  height: 60px;
}

.textField.radio:empty {
  display: none;
}
/* style label */
.textField.radio:empty ~ label {
    text-indent: 0.3em;
    /* margin-top: 2em; */
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
  user-select: none;
  display: flex;
  align-items: center;
}
.textField.radio:empty ~ label:before {
  width: 2.5em;
  background: #D1D3D4;
  border-radius: 3px 0 0 3px;
  margin-right: 5px;
  content: '\2714';
  text-indent: .3em;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777;
}
.fileMsg {
  height: 45px;
  padding-top: 10px;
  color: grey;
}
/* toggle hover */
.textField.radio:hover:not(:checked) ~ label:before {
    content:'\2714';
    /* text-indent: .9em; */
    color: #C2C2C2;
}
.textField.radio:hover:not(:checked) ~ label {
    color: #888;
}
/* toggle on */
.textField.radio:checked ~ label:before {
      content: '\2714';
    text-indent: .3em;
    color: #9CE2AE;
    background-color: #4DCB6D;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.textField.radio:checked ~ label {
    color: #777;
}
/* radio focus */
.textField.radio:focus ~ label:before {
    box-shadow: 0 0 0 3px #999;
}
.ReactTable .rt-td{
  word-break: break-word !important;
  white-space: unset !important;
  text-overflow: unset !important;
}
.tableContainer{
  padding-top: 4em;
  width:85%;
}
.feedbackFlex {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.callStatusContainer {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}
.faCalendarCheckO{
  cursor: pointer;
}
textarea:focus{
  outline: none;
}
.attendedBtn {
  background-color: rgb(36, 165, 87);
  border: 0px;
  padding: 8px 11px;
  color: rgb(255, 255, 255);
  margin: 10px 0px 0px;
  width: auto;
  display: block;
  border-radius: 3px;
  width: 100px;
  cursor: pointer;
}
.attendedBtn:focus{
  outline: none;
}
.not-found {
  color: gray;
}
.quote-imgs-thumbs {
  background: #eee;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin: 1.5rem 0;
  padding: 0.75rem;
}
.quote-imgs-thumbs--hidden {
  display: none;
}
.img-preview-thumb {
  background: #fff;
  border: 1px solid #777;
  border-radius: 0.25rem;
  box-shadow: 0.125rem 0.125rem 0.0625rem rgba(0, 0, 0, 0.12);
  margin-right: 1rem;
  max-width: 140px;
  padding: 0.25rem;
}
.show-for-sr{
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
.button.hollow {
  border: 1px solid #32B265;
  color: #32B265;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  font-family: inherit;
  padding: 0.85em 1em;
  -webkit-appearance: none;
  -webkit-transition: background-color 0.25s ease-out, color 0.25s ease-out;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
}