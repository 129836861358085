.kycForm{
    background-color: #b2b3b7;
    width: 1260px;
    height: 2539px;;
}
.formBody{
    height: 2515px;
    background-color: rgb(255, 255, 255);
    /* height: 89%; */
    font-size: 13px;
    font-weight: bold;
}
  .kycHide{
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
     overflow: hidden;
}
.headerSecGap{
    height: 20px;
    background-color: #b2b3b7;
}
.fixedH67{
    height: 67px;
}
.internmediaryLogo{
    width: 131px;
    text-align: center;
    padding: 8px;
    font-size: 12px;
    border: solid 1px #b2b3b7;
    color: #b2b3b7;
    font-weight: normal;
}
.headerHrLine{
    margin: 0px;
    margin-bottom: 6px;
}


.cont{
    margin-top: 10%;
   margin-left: 15px 
}

.btn{
    background:white;
    display: block;
      color:black;
      border:1px solid black;
      padding: 0.5em 2em;
      text-decoration:none;
    cursor:pointer;
  }