* {
    font-family: 'Avenir Next';
  }
  
  body,
  html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  
  body {
    position: relative;
  }
  
  /* Page Scroll */
  
  * {
    scrollbar-width: thin;
    scrollbar-color: #4ce388 #0a0026;
  }
  
  *::-webkit-scrollbar {
    width: 3px;
    background-color: #0a0026;
  }
  
  *::-webkit-scrollbar-thumb {
    background: #4ce388;
  
    &:hover {
      cursor: pointer;
    }
  }
  
  *::-webkit-scrollbar:hover {
    cursor: pointer;
    width: 5px;
    -webkit-transition: width 0.8s;
    -moz-transition: width 0.8s;
    transition: width 0.8s;
  }
  
  *:hover::-webkit-scrollbar-corner {
    width: 5px;
    cursor: pointer;
    transition: width 0.8s;
  }
  
  .ReactCollapse--collapse {
    transition: height 500ms;
  }
  
  .App {
    transition: opacity .5s ease;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  .cropper-crop {
    border-radius: 8px !important;
  }
  
  .react-datepicker__header,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: #32B265 !important;
  }
  
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  
  .react-datepicker__day-name {
    color: white !important;
  }
  
  .react-datepicker__month-select,
  .react-datepicker__year-select {
    height: 25px;
    border: none;
  
    &:focus,
    &:hover {
      outline: none;
      border: none;
    }
  }
  
  .react-datepicker__header__dropdown {
    margin-top: 5px;
  }
  
  .react-datepicker__current-month {
    color: white !important;
  }
  
  .react-datepicker__navigation {
  
    &:focus,
    &:hover {
      outline: none;
    }
  }
  
  #__lpform_input_idx_2_icon {
    display: none; // input type password image
  }
  
  .react-pdf__Page {
    display: flex;
    justify-content: center;
    // height: 315px;
  }
  
  .react-pdf__Page__canvas {
    user-select: none;
    width: 142px !important;
    //height: 315px !important;
    height: auto !important;
    display: block;
    object-fit: contain;
    overflow-y: scroll;
    // border: 1px solid gray;
    // padding: 6px;
    border-radius: 6px;
  }
  
  #disclosure-modal {
    .react-pdf__Page {
      display: block !important;
    }
  
    .react-pdf__Page__canvas {
      width: 100% !important;
      border: none !important;
      height: auto !important;
    }
  }
  
  .react-pdf__Page__textContent {
    height: inherit !important;
  }
  
  .genericSection {
    height: 100vh;
    padding: 30px;
    overflow: auto;
  }
  
  #scembed-iframe-0 {
    display: none;
  }
  
  .form-container {
    width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f7f7f7;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
  
  .form-heading {
    font-size: 20px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .input-field {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
  
    &:focus {
      border-color: #1E90FF;
    }
  }
  
  .download-btn {
    padding: 12px;
    background-color: #1E90FF;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    &:hover {
      background-color: #1C86EE;
    }
  }
  
  .input-field.invalid {
    border-color: red;
  }