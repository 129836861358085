.popup{
    position: fixed;
    top:0;
    left:0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.2);

    display: flex;
    justify-content: center;
    align-items:center;
}

.popupInner{
    position: relative;
    padding: 32px;
    width: 550px;
    max-width: 740px;
    max-height: fit-content;
    background-color: #FFF;

}

.popupInner .closeBtn{
    position: absolute;
    top: 16px;
    right: 16px;
}

.Container{
    display: flex;
    flex-direction: column;
}

.inputSubCon{
    display: flex;
    margin-top: 5px;
    margin-bottom: 15px;
}

.choice{
    margin-right: 20px;
}

.submit{
        
        border: none;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 16px;
      
}