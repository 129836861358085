
.newsletterContainer{
  display: flex;
  padding: 20px;
  width: 100%;
  height: 100%;
  // justify-content: space-between;
  margin-top: 5%;
}

.mainEditor {
  //display: flex;
  /*align-items: center;*/
  justify-content: center;
  /*padding-top: 5%;*/
  width: 100%;
  height: 700px;
  /* position: absolute;
  right: 5%; */
}

.emailSend{
  margin-top: 10%;
}

.dFlex{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 1%;
  align-items: center;
}
.textarea:focus{
  outline: none;
}

.textArea{
  width:100%;
}


.status {
  margin: 0;
  color: gray;
}

.status label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

.switch {
  position: relative;
  /* display: inline-block; */
  width: 50px;
  height: 25px;
  margin-left: 8px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggleList:checked + .slider {
  background-color: #32B265;
}
.toggleList:focus + .slider {
  box-shadow: 0 0 1px #32B265;
}
.toggleList:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: black;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.6;
}
.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}

.selectCity{
  width: 100%;
  /* margin: auto; */
  margin-bottom: 3%;
}
.selectCity:focus {
outline: none !important;
}

@media screen and (max-width: 1270px) and (min-width: 650px) {
  .selectCity{
    width: 75%;
  }
}

.selectCity{
  width: 100%;
  /* margin: auto; */
  margin-bottom: 3%;
}
.selectCity:focus{
outline: none !important;
}

.textField {
  width: 80%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  font-size: 16px;
  background-color: #E8EEEF;
  margin-top: 15px;
}


.inputFileds{
 justify-content: space-between;
}

.btn{
  background:white;
  display: block;
  margin: 20px auto;
    color:black;
    border:1px solid black;
    padding: 0.5em 2em;
    text-decoration:none;
  cursor:pointer;
  margin-right: 600px;
}
.btn:disabled {
    cursor: not-allowed;
    border: 1px solid #999999;
    color: #666666;
}

.tox .tox-edit-area__iframe, .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary, .tox .tox-menubar{
  background-color: #E8EEEF !important;
}

iframe .mceContenBbody {
  padding: 0 50px !important;
}

iframe {
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;
  box-sizing: border-box;
  height: 600px;
  width: 100%;
  margin-top: 4%;
  background-color: white;
}

iframe .mceContentBody {
  padding: 0 50px !important;
}

// .prevCont{
//   width: 630px;
//   height: 630px;
// }

.ul.inline{
  list-style:none;
  padding: 0;
  margin-bottom:0;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 0px;
}

.tabsDetails{
  margin-top: 3px;
  width: 55vw;
  // height: 68vh;
}


.inline li{
  display:inline-block;
  margin-left:0;
  padding:10px;
  border-bottom:2px solid #eee;
  transition: all .5s;
  font-family:sans-serif;
  font-weight:300;
  cursor:pointer;
  color:#aaa;
}
ul .selected {
  border-bottom:2px solid #32B265;
  color:#444;
}
.submitButton {
  margin-top: 2%;
  padding: 6px 15px;
  border: none;
  border-radius: 8px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.12em;
  border: 1px solid #32B265;
  background: #32B265;
  border-radius: 3px;
  color: #fff;
  transition:
          background-color 400ms linear,
          color 400ms linear;
  &:hover{
    background: transparent;
    color: #32B265;
  }
  &:focus{
    border: 1px solid #32B265;
    outline: none;
  }
  &:disabled {
    background: #A0A0A0;
    color: #fff;
    border: none;
    cursor: not-allowed;
  }
}

.smallCaseSelect{
  width:50%;
  margin-top: 2%;
}