.app{
    display: grid;
    place-items: center;
    height: 100vh;
    background-color: #dadbd3;
}

.app_body{
    display: flex;
    background-color: #ededed;
    margin-top: -50px;
    height:90vh;
    width: 90vw;
}