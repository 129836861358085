.squareCard{
    border: 1px solid #000;
    height: 447px;
}
.squareCard3{
    border: 1px solid #000;
    height: 150px;
}
.squareCard1{
    border: 1px solid #000;
    height: 150px;
}
.squareCard2{
    border: 1px solid #000;
    height: 200px;
}
.clearBoth{
    clear:both;
}
.cardHeader{
    background-color: #b2b3b7;
}
.w126{
    width: 126px;
}
.greyColor{
    color:#b2b3b7;
}
.blackColor{
    color: #000000;
}
.profileImg{
    margin-left: 126px;
    width: 12%;
    flex-grow: initial;
    float: right;
    border: 2px solid #787980;
    position: relative;
    text-align: center;
    clear: both;
}.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:#b2b3b7;
  }
  .photoGraph{
     display: block;
     margin-top: -19px;
  }
  .sign{
    transform: rotate(-22deg);
    margin-top: -28px;
    opacity: 0.4;
  }
  .lineHeight1{
    line-height: 0px;
  }
  .w161{
    width: 161px;
  }
  .w40{
      width: 40%;
  }
.h670{
    height: 670px;
}
.w97{
    width: 97%;
}
.w60{
    width: 60%;
}
.otherAddressDoc{
        width: 46.5%;
        border-bottom: 1px solid #b2b3b7;
        span{
            color: #000;
        }
}
.instructionHead{
    text-align: center;
    background-color: rgb(178, 179, 183);
    color: #000;
    font-weight: bold;
    padding: 7px 0px;
}
.instruction{
    color: rgb(178, 179, 183);
    font-size: 14px;    
}
.h143{
    height: 143px;
}
.w45{
    width: 45%;
}
.singatureBox{
    border: 1px solid #b2b3b7;
    height: 116px;
}
.font12 {
    font-size: 12px;
}
.h184{
    height:184px;
}
.h123 {
    height: 123px;
}
.w73 {
    width:73%;
}
.w52{
    width:52%;
}
.w67{
    width: 66%;
}
.w48{
    width: 48%;
}
.h127{
    height: 127px;
}
.sealStamp{
    display: block;
    margin-top: 6px;
}
.w33{
    width: 32%;
}
.bcWhite{
    background-color: #ffffff;
}
.squareBox{
    width: 22px;
    border: 1px 1px 1px 1px;
    /* border-top: 0px solid #fff; */
    border: 1px solid #b2b3b7;
    // border-top: 1px solid #fff;
    text-align: center;
}
.w45{
    width:47%;
}
.imgSize{
    height:123px; 
    width: 327px;   
}
.profilePhoto{
    height: 143.08px;
    width: 200px;
    margin-top: -2px;
}