$fontfamily-regular: 'FreightSansProBook-Regular';
$loader-size: 4px;
$dot-size: 7px;
$dot-color: #32B265;
$bg-color: #fff;
$slider-height: 2px;
$background-slider: #c7c7c7;
$background-filled-slider: #DEDEDE;
$thumb-background: #DEDEDE;
$thumb-border: 1px solid #777;
$fit-thumb-in-slider: -6px;

.bodyContainer {
  padding-top: 40vh;
  padding-bottom: 20vh;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.flexHorizontalCenter {
  display: flex;
  justify-content: center;
}

.flexVerticalCenter {
  display: flex;
  align-items: center;
}

.flexVertical {
  display: flex;
  flex-direction: column;
}

.flexAllCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexSpaceAround {
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.Button {
  padding: 6px 15px;
  border: none;
  border-radius: 8px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.12em;
  border: 1px solid #32B265;
  background: #32B265;
  border-radius: 3px;
  color: #fff;
  transition:
    background-color 400ms linear,
    color 400ms linear;

  &:hover {
    background: transparent;
    color: #32B265;
  }

  &:focus {
    border: 1px solid #32B265;
    outline: none;
  }

  &:disabled {
    background: #A0A0A0;
    color: #fff;
    border: none;
    cursor: not-allowed;
  }
}

.width_100 {
  width: 100%;
}

.flexRight {
  margin-left: auto;
}

.pointer {
  cursor: pointer;
}

.bold {
  font-weight: 700;
}

.fontBlack {
  color: black;
}

.fontSize16 {
  font-size: 16px;
}

// .textFieldContainer {
//     padding: 5px 0;
// }
.textFieldOutlineWrapper {
  width: 90%;
  display: flex;
  align-items: center;
}

.inputGroupAddon {
  padding: 0px 7px 0px 11px;
  font-size: 14px;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-right: 0;
  border-radius: 8px 0px 0px 8px;
  font-family: 'Roboto';
}

.textField {
  // padding: 9px 15px;
  background: #FFFFFF;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  color: #4a4a4a;
}

.textFieldOutline {
  &:focus {
    outline: none;
  }
}

.addOnTextField {
  // position: relative;
  // z-index: 2;
  // float: left;
  // width: inherit;
  // display: table-cell;
  // padding: 6px 12px;
  margin: 0;
  // border-radius: 0px 8px 8px 0px !important;
  // padding: 9px 15px;
  border: none;
  text-indent: 5px;
  font-family: 'Roboto';
  font-size: 14px;
}

.textFieldOutlined {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  overflow: hidden;
  margin: 0px;
  width: 295px;
  margin: auto;
}


.textFieldOutlined>input {
  box-sizing: border-box;
  margin: 0;
  border: solid 1px lightgray;
  border-top-color: transparent;
  border-radius: 4px;
  padding: 9px 30px;
  width: 295px;
  height: 45px;
  background-color: color;
  box-shadow: none;
  font-family: inherit;
  font-size: 14px;
  line-height: inherit;
  transition: border 0.2s, box-shadow 0.2s;
}


.textFieldOutlined>input+span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  max-height: 100%;
  color: #9b9b9b;
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}


.textFieldOutlined>input+span::before,
.textFieldOutlined>input+span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: solid 1px lightgray;
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.textFieldOutlined>input+span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.textFieldOutlined>input+span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

.textFieldOutlined>input:focus {
  border-top-color: transparent;
  outline: none;
}

.submitButton {
  padding: 6px 15px;
  border: none;
  border-radius: 8px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.12em;
  border: 1px solid #000;
  background: #000;
  border-radius: 8px;
  color: #fff;
  transition:
    background-color 400ms linear,
    color 400ms linear;
  cursor: pointer;

  &:hover {
    background: transparent;
    // -webkit-transform: scale(1.05, 1.05);
    // transform: scale(1.05, 1.05);
    color: #32B265;
  }

  &:focus {
    border: 1px solid #32B265;
    outline: none;
  }
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.label {
  margin: 0;
  cursor: pointer;
  font-family: $fontfamily-regular;
}

.hide {
  display: none;
}

.result {
  height: 390px;
  width: 400px;
  position: relative;
}

.optionText {
  padding: 6px 15px;
  cursor: pointer;
  min-height: 35px;
  text-align: center;
  background-color: #32B265;
  border: 1px solid #32B265;
  box-sizing: border-box;
  border-radius: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-family: $fontfamily-regular;
  transition:
    background-color 400ms linear,
    color 400ms linear;

  &:focus {
    outline: none;
  }

  &:hover {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    background-color: #32B265;
    color: white;
  }
}

.checkmarkCircle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #32b265;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #32b265;
  stroke-miterlimit: 10;
  margin: 3% auto;
  box-shadow: inset 0px 0px 0px #32b265;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmarkCheck {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #fff;
  }
}

// Loader


.loader {
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}


.botLoaderMessage {
  display: flex;
  align-items: center;
  color: #6D6D6D;
  width: fit-content;

  div {
    padding-left: 5px;

    .pulse__one {
      animation-delay: 250ms;
    }

    .pulse__two {
      animation-delay: 500ms;
    }

    .pulse__three {
      animation-delay: 750ms;
    }
  }

  .loader__dot {
    display: block;
    width: $loader-size;
    height: $loader-size;
    margin: ($loader-size/2.5);
    border-radius: ($loader-size/2);
    background-color: #90949c;
    float: left;
    will-change: transform, opacity;
    animation: pulse 1.25s infinite ease;
  }
}

.loader div {
  //  position: absolute;
  //  top: 45%;
  //  left: 45%;
  position: absolute;
  top: 59%;
  left: 48%;

  .pulse__one {
    animation-delay: 250ms;
  }

  .pulse__two {
    animation-delay: 500ms;
  }

  .pulse__three {
    animation-delay: 750ms;
  }
}


.loader__dot {
  display: block;
  width: $dot-size;
  height: $dot-size;
  margin: ($dot-size/2.5);
  border-radius: ($dot-size/2);
  background-color: darkslategray;
  float: left;
  will-change: transform, opacity;
  animation: pulse 1.25s infinite ease;
}


// Pulse animation

@keyframes pulse {
  0% {
    opacity: 0.5;
    transform: scale(1);
  }

  50% {
    opacity: 1;
    transform: scale(1.25);
  }

  100% {
    opacity: 0.5;
    transform: scale(1);
  }
}

.width_100 {
  width: 100%;
}

.hoverable :hover {
  transform: scale(1.1);
  transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28) 50ms;
}

.sibDateFeild {
  background: white !important;
  border: #fff 1px solid !important;
  color: #000000 !important;
}

.disabledField {
  cursor: not-allowed !important;
  background: #d3d3d3 !important;
  border: #fff 1px solid !important;
  color: #000000 !important;
  opacity: 0.5;

  &:hover {
    border: #fff 1px solid !important;
    color: #000000 !important;
    transform: none !important;
  }
}

.basis50 {
  flex-basis: 50%;
}

.basis70 {
  flex-basis: 70%;
}

.chatBotVpaContainer {
  width: 95%;
}

.chatBotPhoneContainer {
  width: 90%;
  display: flex;
  align-items: center;
}


.flexAllCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexVerticalAllCenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flexVerticalCenter {
  display: flex;
  align-items: center;
}

.flexHorizontalCenter {
  display: flex;
  justify-content: center;
}

.flexRightAlign {
  margin-left: auto;
}

.paddingHorizontal5 {
  padding: 0 5px;
  cursor: pointer;
}

.marginBottom5 {
  margin-bottom: 5px;
}

.boxShadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.chatbotContainer {
  position: fixed;
  top: 0;
  right: 0px;
  width: 29.9%;
  //height: 100vh;
  z-index: 9999;
  box-sizing: border-box;
  border-radius: 16px;
  height: 100%;
  overflow-y: scroll;
}


.chatWindow::-webkit-scrollbar {
  width: 0px;
}

.circle {
  width: 13px;
  height: 13px;
  background: #3CD57C 0% 0% no-repeat padding-box;
  border: 2px solid #FFFFFF;
  border-radius: 7px;
  opacity: 1;
  margin-right: 5px;
}

.chatbotHeader {
  height: 60px;
  color: #808080;
  // background: white;
  // border-top-left-radius: 16px;
  // border-top-right-radius: 16px;
  // background: #F2F3F8;
  // border: 1px solid #EFEFEF;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: $fontfamily-regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  div {
    font-family: $fontfamily-regular;
  }
}

.chatHeaderMsg {
  color: #000000;
  //font: normal normal 600 12px/17px Roboto;
  font-size: 15px;
}

.title {
  flex-basis: 80%;
  display: flex;
  align-items: center;
}

.userLogin {
  font-size: 13px;
}

.chatWindow {
  display: flex;
  flex-direction: column;
  // height: inherit;
  padding-bottom: 5px;
  overflow: auto;
  padding: 15px 5px 5px 5px;
  scrollbar-width: none;
  overflow-x: hidden;
  height: calc(100% - 60px);
  // background: url('/static/images/whiteImg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.chatBalloon {
  position: fixed;
  bottom: 10px;
  right: 25px;
  background: #32B265;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  cursor: pointer;
  z-index: 9999;
}

.botMessage {
  display: flex;
  padding: 10px;
  max-width: 90%;
  //white-space: pre
}

.botMessageText {
  margin-left: 5px;
  font-family: $fontfamily-regular;
  background: #E3F8E5;
  padding: 14px 16px 16px 16px;
  border-radius: 5px;
  //font: normal normal normal 14px/16px Roboto;
  color: #258055;
  font-style: normal;
  // font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  // color: #000000;
  // padding: 4%;
  opacity: 0;
  border-bottom-left-radius: 0px;
  animation-name: fade;
  animation-duration: 0.75s;
  animation-delay: 400ms;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-direction: normal;
  /* how animation direction starts or ends */
  animation-fill-mode: forwards;
  /* what it looks like after element gets animated */
  animation-play-state: running;

  /* animation pause or play */
  >div {
    font-family: $fontfamily-regular;
  }
}

.botMessageTitle {
  //font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #258055;
  // height: 35px;
  display: flex;
  align-items: center;
  line-height: 18px;
  font-family: $fontfamily-regular;

  +div {
    margin-top: 5px;
  }
}

.iconInputGroupAddon {
  padding: 0 14px 0 43px;
  font-size: 14px;
  color: #555;
  border-right: 0;
  font-family: "Roboto";
  color: #00000099;
  position: absolute;
}

.iconTextFieldContainer {
  width: 100%;
  // margin-bottom: 10px;
  display: flex;
  align-items: center;

  svg {
    position: absolute;
    color: #70DB8B;
    min-width: 45px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-top: 13px;
    font-size: 22px;
  }
}

.iconTextField {
  width: 265px;
  padding: 10px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #0000001F;
  border-radius: 4px;
  text-indent: 63px;
  height: 48px;
  //font-family: $fontfamily-regular;
  font-family: 'Roboto';
  //font: normal normal medium 14px/25px Roboto;
  letter-spacing: 0.13px;
  font-size: 14px;
  color: #00000099;

  &:focus {
    outline: none;
  }
}

.marginTop {
  margin-top: 10px !important;
}

.btnWrapper {
  display: flex;
  height: 40px;
  margin: 0 -15px -15px -15px;
  margin-top: 10px;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: scale(0, 0);
  }

  25% {
    opacity: 0.35;
    transform: scale(0, 0);
  }

  50% {
    opacity: 0.5;
    transform: scale(0.8, 0.8);
  }

  70% {
    opacity: 0.75;
    transform: scale(1.1, 1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

.botMessageFullWidth {
  width: 95%;

  >div {
    text-align: center;
  }
}

.botLoaderMessage {
  width: fit-content;
  margin: 14px 0 0 14px;
}

.clientMessage {
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  white-space: pre;
}


.clientMessageText {
  //font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  // padding: 14px 16px 16px 16px;
  padding: 10px 15px;
  background: #42BF64 0% 0% no-repeat padding-box;
  border-radius: 5px 5px 0px 5px;
  // padding: 8px 15px;
  margin-right: 5px;
  // background: #32B265;
  // border: 1px solid #32B265;
  box-sizing: border-box;
  // border-radius: 16px;
  font-style: normal;
  // font-weight: 500;
  // font-size: 15px;
  // line-height: 20px;
  text-align: right;
  color: #FFFFFF;
  font-family: $fontfamily-regular;
  // border-bottom-right-radius: 0px;
  opacity: 0;
}

.chatContainerImageWrapper {
  background: white;
  border-radius: 50%;
  padding: 5px;
}

.chatSuggestions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding-top: 5px;
  flex-flow: row wrap;
  flex-shrink: 0;
}

.optionText {
  padding: 9px 15px 8px 15px;
  //font: normal normal normal 14px/16px Roboto;
  cursor: pointer;
  min-height: 35px;
  text-align: center;
  background: #42BF64 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 34px;
  opacity: 1;
  box-sizing: border-box;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: white;
  font-family: $fontfamily-regular;
  transition:
    background-color 400ms linear,
    color 400ms linear;

  &:focus {
    outline: none;
  }

  &:hover {
    -webkit-transform: scale(1.05, 1.05);
    transform: scale(1.05, 1.05);
    background-color: #42BF64;
    color: white;
  }
}

.hide {
  display: none;
}

.textFieldOutline {
  &:focus {
    outline: none;
  }
}

.label {
  margin: 0;
  cursor: pointer;
  font-family: $fontfamily-regular;
}

.crop {
  height: 190px;
  width: 300px;
}

.chatContainer {
  margin-bottom: 25px;
}

.result {
  height: 390px;
  width: 400px;
}

.cropWrapper {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  height: 290px;
  width: 400px;
}

.imageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.croppedImage {
  height: 100px;
  width: 150px;
  padding-bottom: 10px;
}

.textField {
  height: 48px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #0000001F;
  border-radius: 4px;
  //font: normal normal medium 14px/25px Roboto;
  letter-spacing: 0.13px;
  color: #00000099;
  width: 265px;
  display: flex;
  align-items: center;
  text-indent: 20px;
  //font-family: 'Roboto';
}

input[type=number] {
  font-family: 'Roboto';
}

// .longText {
//   // width: 320px;
// }

// .fieldContainer {
//   // width: 330px;
// }

.textFieldContainer {
  padding: 5px 0;
}

.chatbotLogo {
  margin-right: 13px;
}

.inputGroupAddon {
  padding: 12.5px 7px 12.5px 8px;
  font-size: 14px;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #fff;
  border-right: 0;
  border-radius: 8px 0px 0px 8px;
}

.ballonGroup {
  position: relative;
  display: flex;
  width: 32px;
  height: 32px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transform: scale(1);
  transition: transform 200ms cubic-bezier(0.18, 0.89, 0.32, 1.28) 50ms;

}

.chatSquare {
  display: inline-block;
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  font-size: 2rem;
  user-select: none;
  fill: currentcolor;
}

.chatBtn {
  position: absolute;
  border-radius: 5px;
  z-index: 1;
  left: 0px;
  right: 0px;
  bottom: 1px;
  display: flex;
  width: 32px;
  height: 30px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms cubic-bezier(0.33, 0, 0.67, 1) 50ms;
}

.textFieldOutlined {
  position: relative;
  display: inline-block;
  padding-top: 6px;
  overflow: hidden;
  margin: 0px;
  width: 265px;
  margin: auto;
}


.textFieldOutlined>input {
  box-sizing: border-box;
  margin: 0;
  background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
  border: 1px solid rgba(98, 0, 238, 1);
  border-top-color: transparent;
  border-radius: 4px;
  // padding: 9px 30px;
  width: 265px;
  height: 48px;
  box-shadow: none;
  font-family: inherit;
  font-size: 14px;
  line-height: inherit;
  transition: border 0.2s, box-shadow 0.2s;
}


.textFieldOutlined>input+span {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  max-height: 100%;
  color: gray;
  font-size: 75%;
  line-height: 15px;
  cursor: text;
  transition: color 0.2s, font-size 0.2s, line-height 0.2s;
}


.textFieldOutlined>input+span::before,
.textFieldOutlined>input+span::after {
  content: "";
  display: block;
  box-sizing: border-box;
  margin-top: 6px;
  border-top: 1px solid rgba(98, 0, 238, 1);
  min-width: 10px;
  height: 8px;
  pointer-events: none;
  box-shadow: inset 0 1px transparent;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.textFieldOutlined>input+span::before {
  margin-right: 4px;
  border-left: solid 1px transparent;
  border-radius: 4px 0;
}

.textFieldOutlined>input+span::after {
  flex-grow: 1;
  margin-left: 4px;
  border-right: solid 1px transparent;
  border-radius: 0 4px;
}

.textFieldOutlined>input:focus {
  border-top-color: transparent;
  outline: none;
}

.addOnTextField {
  position: relative;
  z-index: 2;
  float: left;
  width: inherit;
  display: table-cell;
  padding: 6px 12px;
  margin: 0;
  border-radius: 0px 8px 8px 0px !important;
  padding: 9px 15px;
}

.tutorialShow {
  // transition: height 500ms 0ms, opacity 500ms 500ms;
  width: 100%;
  outline: 0;
}

.tutorialHide {
  transition: height 500ms 500ms, opacity 500ms 0ms;
  opacity: 0.0;
  height: 0px;
  max-width: 100%;
}

.tutorialText {
  float: right;
  cursor: pointer;
  color: #32B265;
  font-size: 16px;
  margin-top: -8px;
  margin-right: -3px;

  &:hover {
    text-decoration: underline;
  }
}

.chatIcon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.botIconContainer {
  background: white;
  border-radius: 50%;
  padding: 2px;
}

.link {
  color: #32b265;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.userTextArea {
  flex-basis: 80%;
  border-radius: 3px;
  padding: 3px 5px 0 5px;
  max-height: 50px;
  resize: none;
}

.userInputGap {
  flex-basis: 5%;
}

.divButton {
  //border-radius: 3px;
  //cursor: pointer;
  flex-basis: 10%;

  //padding: 10px;
  //background: #32B265;
  //color: #FFFFFF;
  // &:hover {
  //   //transform: scale(1.05);
  // }
}

.userCustomButton {
  border-radius: 50%;
  cursor: pointer;
  padding: 6px 8px 8px 10px;
  background: #32B265;
  color: #FFFFFF;

  &:hover {
    transform: scale(1.05);
  }
}

.olderChatMsg {
  font-family: $fontfamily-regular;
  //font: normal normal normal 14px/16px Roboto;
  letter-spacing: 0px;
  color: #258055;
  /* height: 35px; */
  display: flex;
  align-items: center;

}

.disabledDiv {
  cursor: not-allowed;
  background: #5a6268;
}

.flexUserInput {
  display: flex;
  padding: 0 10px;
  align-items: center;
}

.divHalfButton {
  cursor: pointer;
}

.closeDialog {
  font-size: 18px;
  cursor: pointer;
}

.menuIcon {
  font-size: 20px;
  cursor: pointer;
  border-radius: 50%;
  position: relative;
  padding: 3px;

  // &:hover {
  //   //background: #C0C0C0;
  // }
}

.menuSelected {
  background: #C0C0C0;
}

.menuContainer {
  z-index: 2;
  position: absolute;
  border-radius: 2px;
  background: #F0F0F0;
  left: -150px;
  top: 30px;
  width: 170px;
  //border: 1px solid #808080;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.menuRow {
  cursor: pointer;
  padding: 3px;
  height: 30px;
  font-size: 14px;

  &:hover {
    background: #C0C0C0;
  }
}

@media screen and (max-width: 1240px) and (min-width: 1063px) {
  .chatbotContainer {
    width: 35%;
  }
}

@media screen and (max-width: 1062px) and (min-width: 931px) {
  .chatbotContainer {
    width: 40%;
  }
}

@media screen and (max-width: 930px) and (min-width: 828px) {
  .chatbotContainer {
    width: 45%;
  }
}

@media screen and (max-width: 827px) and (min-width: 745px) {
  .chatbotContainer {
    width: 50%;
  }
}

@media screen and (max-width: 744px) and (min-width: 703px) {
  .chatbotContainer {
    width: 53%;
  }
}

@media screen and (max-width: 702px) {
  .chatbotContainer {
    width: 100%;
    // position: inherit;
  }
}