$font-family: 'FreightSansProSemibold-Regular';
$font-family-bold: 'FreightSansProBold-Regular';
$font-family-regular:'FreightSansProBook-Regular';

.loginContainer {
    display: flex;
    justify-content: center;
    margin-top: -100px;
}

.phoneNoContainer, .otpContainer {
    text-align: left;
    // box-shadow: 0 1.5px 4px rgb(0 0 0 / 12%), 0 1.5px 6px rgb(0 0 0 / 6%);
    // padding: 40px;
}

.signupTitle {
    font-size: 35px;
    height: 60px;
}

.subTitle {
    height: 30px;
    color: #9b9b9b;
    font-family: $font-family-regular;
    font-size: 18px;
    span {
        font-family: 'Roboto';
    }
}

.otpMsg {
    height: 30px;
    color: #9b9b9b;
    font-family: $font-family-regular;
    font-size: 14px;
}
.cursorPointer {
    cursor: pointer;
}
.phoneInputFieldWrapper {
    margin: 20px 0 6px 0;
}
.phoneTextFieldOutline {
    border: 1px solid #EFEFEF;
    width: 300px !important;
    height: 45px;
    border-radius: 3px;
}

.loginBtn {
    width: 135px;
    border-radius: 0px !important;
    padding: 9px 15px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.loginBtnWrapper {
    height: 60px;
}

.textFieldWrapper {
    padding-bottom: 20px;
}

.errorMsg {
    font-size: 12px;
    color: red;
    width:300px;
    // position: relative;
    // top: -15px;
  }

  .otpField {
    height: 45px;
    width: 300px !important;
    text-indent: 10px;
    border-radius: 3px !important;
    font-family: 'Roboto';
    font-size: 14px;
    &:focus {
        outline: none;
    }
  }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

//  .landingImg {
//      width: 100vw;
//      opacity: 0.4;
//      background: url('./Images/landing horizontal2.jpg');
//      background-size: 100% 100%;
//      -webkit-background-size: 100% 100%;
//      -moz-background-size: 100% 100%;
//      -o-background-size: 100% 100%;
//      background-attachment: scroll;
//      height: 100vh;
//      z-index: 5;
//      @media (max-width: 600px) {
//        background: url('./Images/landing vertical.jpg');
//        background-size: 100% 100%;
//      }
//    }
  