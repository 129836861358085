
.CardContainer{
  box-shadow: 4px 3px 8px 0px rgb(0, 0, 0);
  position: fixed;
    width: 350px;
    margin-left: 35%;
    margin-top: 13%;
    max-height: fit-content;
    display: flex;
    justify-content: center;
    align-items:center;

}

.fileInput{
  margin-top: 10px;
}

.lastUpdated{
  margin-top: 35px;
}

.myIcon{
  margin-top: -100px;
}


